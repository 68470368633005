export const MODAL_STATUS = {
  PLACE_ORDER: "PLACE_ORDER",
  SELECT_PAYMENT_TYPE: "SELECT_PAYMENT_TYPE",
  PAY: "PAY",
  CHECKOUT: "CHECKOUT",
  SUCCEEDED: "SUCCEEDED",
};
export const PAYMENT_METHODS = {
  PAYPAL: "paypal",
  ADYEN: "adyen",
  STRIPE: "stripe",
  CASH: "cash",
  CUSTOM_OPTIONS: "customOptions",
};
