/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMainSlice } from "../../../pages/main/actions";
import {
  selectAdyenPaymentIntegration,
  selectAdyenPaymentMethods,
  selectCartData,
  selectIsAdyenloading,
  selectRestaurantCurrency,
  selectRestaurantId,
} from "../../../pages/main/selectors";
import { AdyenCheckout, Dropin } from "@adyen/adyen-web/auto";
import "@adyen/adyen-web/styles/adyen.css";
import { request } from "../../../utils/request";
import { API } from "../../../pages/main/constants";
import { v4 as uuidv4 } from "uuid";
import configs from "../../../configs";
import { useTranslation } from "react-i18next";

export default function AdyenCheckoutForm({ onSuccess }) {
  const paymentDivref = useRef();
  const dispatch = useDispatch();
  const { actions } = useMainSlice();
  const currency = useSelector(selectRestaurantCurrency);
  const restaurantId = useSelector(selectRestaurantId);
  const cartData = useSelector(selectCartData);
  const adyenPaymentMethods = useSelector(selectAdyenPaymentMethods);
  const adyenIntegration = useSelector(selectAdyenPaymentIntegration);
  const isAdyenLoading = useSelector(selectIsAdyenloading);
  const uuid = uuidv4();
  const { t } = useTranslation();

  const getPaymentMethods = useCallback(() => {
    dispatch(
      actions.getAdyenPaymentMethods({
        restaurantId,
        currency,
        data: cartData,
        orderReference: uuid,
      })
    );
  }, [dispatch, actions, restaurantId, currency, cartData]);

  useEffect(() => {
    getPaymentMethods();
    return () => {
      dispatch(actions.clearPaymentVerifiedDetails());
    };
  }, [getPaymentMethods]);

  const makePaymentsCall = async (data) => {
    try {
      const result = await request(
        API.GET_ADYEN_PAYMENT_REQUEST,
        {
          restaurantId,
          amount: cartData.total,
          data,
          orderReference: uuid,
          currency,
          returnUrl: `${window.origin}${window.location.pathname}?cart=true`,
        },
        false
      );
      return result.data;
    } catch (error) {
      return { resultCode: false };
    }
  };

  const makeDetailsCall = async (data) => {
    try {
      const result = await request(
        API.GET_ADYEN_PAYMENT_DETAILS,
        { restaurantId, data },
        false
      );
      return result.data;
    } catch (error) {
      return { resultCode: false };
    }
  };

  useEffect(() => {
    // Create and mount Adyen checkout only once payment methods are fetched
    const initializeCheckout = async () => {
      if (adyenPaymentMethods) {
        const configuration = {
          clientKey: adyenIntegration.clientId,
          environment: configs.ADYEN_ENV,
          amount: {
            vaGET_ADYEN_PAYMENT_REQUESTlue: 1000,
            currency: currency,
          },
          locale: "en",
          countryCode: adyenIntegration.country,
          paymentMethodsResponse: adyenPaymentMethods,
          onSubmit: async (state, component, actions) => {
            try {
              // Make a POST /payments request from your server.
              const result = await makePaymentsCall(state.data);
              // If the /payments request from your server fails, or if an unexpected error occurs.
              if (!result.resultCode) {
                actions.reject();
                return;
              }
              const { action } = result;

              if (action) {
                component.handleAction(action);
              } else {
                actions.resolve(result);
              }
            } catch (error) {
              actions.reject();
            }
          },
          onAdditionalDetails: async (state, component, actions) => {
            try {
              const result = await makeDetailsCall(state.data);

              if (!result.resultCode) {
                actions.reject();
                return;
              }

              const { action } = result;

              if (action) {
                component.handleAction(action);
              } else {
                actions.resolve(result);
              }
            } catch (error) {
              console.error("onSubmit", error);
              actions.reject();
            }
          },
          onPaymentCompleted: (result, component) => {
            onSuccess({ orderId: uuid });
          },
          onPaymentFailed: (result, component) => {
            console.info("Payment failed ", result, component);
          },
          onError: (error, component) => {
            console.error(error.name, error.message, error.stack, component);
          },
        };

        try {
          const checkout = await AdyenCheckout(configuration);
          new Dropin(checkout).mount("#dropin");
        } catch (error) {
          console.error("Adyen Checkout initialization error:", error);
        }
      }
    };

    initializeCheckout();
  }, [adyenPaymentMethods]);

  return (
    <>
      <div id="dropin" ref={paymentDivref}></div>
      {isAdyenLoading && <p>{t("cart.initializing")}</p>}
    </>
  );
}
