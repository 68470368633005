import { PROMOTION_TYPES } from "../../constants";
import {
  calculateCartPercentageDiscount,
  calculateCartAmountDiscount,
  totalPriceCalculation,
  calculateItemTaxs,
  calculateCartItemDiscount
} from "./helper";

const calculateCartData = (items, promotions) => {

  //calculate item discount
  const itemDiscountData = calculateCartItemDiscount(items,promotions);

  const itemTotal = totalPriceCalculation(itemDiscountData.items);
  const availablePromotions = promotions.filter(
    (promotion) =>
      (promotion.promotionType === PROMOTION_TYPES.DiscountOnCart || promotion.promotionType === PROMOTION_TYPES.FixedDiscountOnCart) &&
      itemTotal >= promotion.minOrderAmount
  );

  const { percentagePromotions, amountPromotions } = availablePromotions.reduce(
    (acc, item) => {
      if (item.promotionType === PROMOTION_TYPES.DiscountOnCart) {
        acc.percentagePromotions.push(item);
      } else if (item.promotionType === PROMOTION_TYPES.FixedDiscountOnCart) {
        acc.amountPromotions.push(item);
      }
      return acc;
    },
    { percentagePromotions: [], amountPromotions: [] }
  );

  const percentageDiscountData = calculateCartPercentageDiscount(
    itemDiscountData.items,
    percentagePromotions
  );

  const amountDiscountData = calculateCartAmountDiscount(
    percentageDiscountData.items,
    amountPromotions
  );

  const tax = calculateItemTaxs(amountDiscountData.items);

  return {
    cartItemCount: items.length,
    items: amountDiscountData.items,
    discount:
      Math.round((amountDiscountData.totalDiscount + percentageDiscountData.totalDiscount)*100)/100,
    sub_total: itemTotal,
    total:
    Math.max(
      Math.round(
        (itemTotal -
          (amountDiscountData.totalDiscount +
            percentageDiscountData.totalDiscount) +
          tax.tax_not_included) *
          100
      ) / 100,
      0 // Ensure total is at least 0
    ),
    promotions: percentageDiscountData.promotions.concat(
      amountDiscountData.promotions
    ),
    tax_included: Math.round(tax.tax_included*100)/100,
    tax_not_included: Math.round(tax.tax_not_included*100)/100,
  };
};

export const addToCartLoyvers = (state, payload) => {
  const cartData = JSON.parse(JSON.stringify(state.cartData));
  const promotions = JSON.parse(JSON.stringify(state.promotions));

  const newItem = { ...payload };
  const items = [...cartData.items, newItem];

  return calculateCartData(items, promotions);
};

export const removeCartLoyvers = (state, payload) => {
  const cartData = JSON.parse(JSON.stringify(state.cartData));
  const promotions = JSON.parse(JSON.stringify(state.promotions));

  const items = [...cartData.items];
  items.splice(payload, 1);

  return calculateCartData(items, promotions);
};
