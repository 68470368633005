const mode = window.location.host;

let ROOT_BACKEND_API = "http://localhost:4040/api";
let APP_DOMAIN = "http://localhost:3000";
let SOCKET_ENDPOINT = "http://localhost:4040";
// let RESOURCES_BUCKET_URL =
//   'https://products-nepseeds-resources.s3.ca-central-1.amazonaws.com';
let RESOURCES_BUCKET_URL =
  "https://menutigr-resources.s3.us-west-2.amazonaws.com";
let STRIPE_PK =
  "pk_test_51K52k7BlBJOreIXbsAYNOuM0P33TfZX56HS1Mon9N7HesYXcwkrw65dQDKF3rzw99ikVM6TOLmkx8lcRp06nbhwV00iwSABbZ0";
const MAIN_SITE_URL = "https://menu.qrcode-tiger.com";
let ADYEN_ENV = "test";

switch (mode) {
  case "dev.menutigr.com":
    ROOT_BACKEND_API = "https://api.dev.menutigr.com/api";
    APP_DOMAIN = "https://dev.menutigr.com";
    SOCKET_ENDPOINT = "https://api.dev.menutigr.com";
    break;
  case "menutigr.com":
    ROOT_BACKEND_API = "https://alb.menutigr.com/api";
    APP_DOMAIN = "https://menutigr.com";
    SOCKET_ENDPOINT = "https://alb.menutigr.com";
    STRIPE_PK =
      "pk_live_51K52k7BlBJOreIXbfA5uuumYhvofs4ZRlojxhfLrt8MtsObBFwhtM4nJM3xz6BjcKWejEx5OxIiaycQz5qIfKJCG00I3P66NDb";
    break;
  case "localhost:3001":
    ROOT_BACKEND_API = "http://localhost:4040/api";
    // ROOT_BACKEND_API = "https://api.dev.menutigr.com/api";
    APP_DOMAIN = "localhost";
    SOCKET_ENDPOINT = "http://localhost:4040";
    // SOCKET_ENDPOINT = "https://api.dev.menutigr.com/api";
    break;
  default:
    ROOT_BACKEND_API = "https://alb.menutigr.com/api";
    SOCKET_ENDPOINT = "https://alb.menutigr.com";
    ADYEN_ENV = " live";
    STRIPE_PK =
      "pk_live_51K52k7BlBJOreIXbfA5uuumYhvofs4ZRlojxhfLrt8MtsObBFwhtM4nJM3xz6BjcKWejEx5OxIiaycQz5qIfKJCG00I3P66NDb";
    // ROOT_BACKEND_API = 'https://api.dev.menutigr.com/api';
    // SOCKET_ENDPOINT = 'https://api.dev.menutigr.com';
    APP_DOMAIN = mode;
    // SOCKET_ENDPOINT = 'http://localhost:4040';
    break;
}

let exportModules = {
  ROOT_BACKEND_API,
  APP_DOMAIN,
  RESOURCES_BUCKET_URL,
  SOCKET_ENDPOINT,
  STRIPE_PK,
  MAIN_SITE_URL,
  ADYEN_ENV,
};
export default exportModules;
