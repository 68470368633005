import React from "react";
import "./styles.scss";

import LoadingImage from "../../images/loading.gif";

export default function Loading(props) {
  return (
    <div className={`loading ${props.className}`}>
      <img src={LoadingImage} alt="" />
    </div>
  );
}
