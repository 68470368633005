import { PROMOTION_TYPES } from "../../constants";
export const totalPriceCalculation = (items) => {
  return items.reduce((total, item) => total + item.discountedPrice, 0);
};

export const itemTaxCalculation = (price, tax) => {
  return (tax / 100) * price;
};

export const calculateDiscountDetails = (
  promotions,
  itemPrice,
  totalItemPrice = null
) => {
  let totalDiscount = 0;
  let remainingTotal = itemPrice;
  const discounts = [];

  for (let i = 0; i < promotions.length; i++) {
    let discountValue = 0;
    if (promotions[i].promotionType === PROMOTION_TYPES.DiscountOnCart) {
      discountValue = (promotions[i].discountValue * remainingTotal) / 100;
    } else if (
      promotions[i].promotionType === PROMOTION_TYPES.FixedDiscountOnCart
    ) {
      if (totalItemPrice > 0) {
        discountValue =
          promotions[i].discountValue * (remainingTotal / totalItemPrice);
      }
    }

    const discount = Math.round(discountValue * 100) / 100;
    totalDiscount += discount;
    remainingTotal -= discount;
    discounts.push({
      id: promotions[i]?._id,
      name: promotions[i]?.name,
      type: promotions[i]?.promotionType,
      discount: discount,
      externalPosDiscountDetails: promotions[i]?.externalPosDiscountDetails,
    });
  }

  return {
    totalDiscount: Math.round(totalDiscount * 100) / 100,
    discounts: discounts,
  };
};

export const calculateCartItemDiscount = (items, discounts) => {
  // Initialize an array to store the items with their discounts
  const discountedItems = items.map((item) => ({
    ...item,
    totalDiscount: 0,
    discountedPrice: item.sub_total,
    promotions: [],
  }));

  // Apply discounts to the respective items
  discounts.forEach((discount) => {
    if (discount.type === "ITEM") {
      discountedItems.forEach((item) => {
        let discountAmount = 0;
        if (discount.foodIds.includes(item?.food?._id)) {
          if (discount.isAddOns) {
            const itemPrice = item.sub_total;
            discountAmount = (itemPrice * discount.discountValue) / 100;
            item.totalDiscount += discountAmount;
            item.discountedPrice -= discountAmount;
          } else {
            const itemPrice = item.food.sub_total;
            discountAmount = (itemPrice * discount.discountValue) / 100;
            item.totalDiscount += discountAmount;
            item.discountedPrice -= discountAmount;
          }
          item.promotions.push({
            id: discount._id,
            name: discount.name,
            externalPosDiscountDetails: discount?.externalPosDiscountDetails,
            discount: discountAmount,
            type: discount.type,
          });
        }
      });
    }
  });

  // Calculate the overall total discount
  const overallTotalDiscount = discountedItems.reduce(
    (acc, item) => acc + item.totalDiscount,
    0
  );

  return { items: discountedItems, totalDiscount: overallTotalDiscount };
};

export const calculateCartPercentageDiscount = (items, discounts) => {
  let totalDiscount = 0.0;
  let allDiscounts = [];

  const calculatedItems = items.map((item) => {
    const discountDetails = calculateDiscountDetails(
      discounts,
      item.sub_total,
      10
    );

    totalDiscount += discountDetails.totalDiscount;
    allDiscounts = allDiscounts.concat(discountDetails.discounts);
    return {
      ...item,
      discountedPrice: item.sub_total - discountDetails.totalDiscount,
    };
  });

  return {
    items: calculatedItems,
    promotions: sumDiscountsById(allDiscounts),
    totalDiscount,
  };
};

export const calculateCartAmountDiscount = (items, promotions) => {
  let totalDiscount = 0.0;
  let allDiscounts = [];
  //calculate total item price
  const totalItemPrice = items.reduce(
    (total, item) => total + item.discountedPrice,
    0
  );
  //item wise calculations
  const calculatedItems = items.map((item) => {
    const discountDetails = calculateDiscountDetails(
      promotions,
      item.discountedPrice,
      totalItemPrice
    );
    totalDiscount += discountDetails.totalDiscount;
    allDiscounts = allDiscounts.concat(discountDetails.discounts);
    return {
      ...item,
      discountedPrice: item.discountedPrice - discountDetails.totalDiscount,
    };
  });
  return {
    items: calculatedItems,
    promotions: sumDiscountsById(allDiscounts),
    totalDiscount,
  };
};

export const calculateItemDiscount = (items, promotions) => {
  let totalDiscount = 0.0;
  let allDiscounts = [];
  //calculate total item price
  const totalItemPrice = items.reduce(
    (total, item) => total + item.discountedPrice,
    0
  );
  //item wise calculations
  const calculatedItems = items.map((item) => {
    const discountDetails = calculateDiscountDetails(
      promotions,
      item.discountedPrice,
      totalItemPrice
    );
    totalDiscount += discountDetails.totalDiscount;
    allDiscounts = allDiscounts.concat(discountDetails.discounts);
    return {
      ...item,
      discountedPrice: item.discountedPrice - discountDetails.totalDiscount,
    };
  });
  return {
    items: calculatedItems,
    promotions: sumDiscountsById(allDiscounts),
    totalDiscount,
  };
};

export const sumDiscountsById = (discountArray) => {
  const discountSumById = {};

  for (const discount of discountArray) {
    const { id, discount: discountAmount } = discount;
    if (!discountSumById[id]) {
      discountSumById[id] = {
        id,
        name: discount.name,
        type: discount.type,
        discount: 0,
        externalPosDiscountDetails: discount.externalPosDiscountDetails,
      };
    }
    discountSumById[id].discount =
      Math.round((discountSumById[id].discount + discountAmount) * 100) / 100;
  }

  return Object.values(discountSumById);
};

export const calculateItemTaxs_old = (items) => {
  let tax_included = 0.0;
  let tax_not_included = 0.0;
  items.forEach((item) => {
    if (item?.food?.taxes && item?.food?.taxes.length > 0) {
      item.food.taxes.forEach((tax) => {
        let taxAmount = 0;

        if (item.discountedPrice > 0) {
          taxAmount = itemTaxCalculation(item.discountedPrice, tax.take_out);
        }

        if (tax.type === "NOT_INCLUDE_TO_PRICE") {
          tax_not_included += taxAmount;
        } else {
          tax_included += taxAmount;
        }
      });
    }
  });

  return {
    tax_included,
    tax_not_included,
  };
};

export const calculateItemTaxs = (items) => {
  const taxMapIncluded = new Map();
  const taxMapNotIncluded = new Map();

  items.forEach((item) => {
    item.food.taxes.forEach((tax) => {
      const taxId = tax._id;
      const taxValue = tax.take_out;
      if (tax.type === "NOT_INCLUDE_TO_PRICE") {
        const totalTax = taxMapNotIncluded.get(taxId) || 0;
        taxMapNotIncluded.set(
          taxId,
          totalTax + (item.discountedPrice * taxValue) / 100
        );
      } else {
        const totalTax = taxMapIncluded.get(taxId) || 0;
        taxMapIncluded.set(
          taxId,
          totalTax + (item.discountedPrice * taxValue) / 100
        );
      }
    });
  });
  const resultTaxIncluded = [];
  const resultTaxNotIncluded = [];
  for (const [taxId, totalTax] of taxMapIncluded.entries()) {
    resultTaxIncluded.push({
      id: taxId,
      totalTaxCalculations: Math.round(totalTax * 100) / 100,
    });
  }
  for (const [taxId, totalTax] of taxMapNotIncluded.entries()) {
    resultTaxNotIncluded.push({
      id: taxId,
      totalTaxCalculations: Math.round(totalTax * 100) / 100,
    });
  }
  return {
    tax_included: resultTaxIncluded.reduce(
      (total, tax) => total + tax.totalTaxCalculations,
      0
    ),
    tax_not_included: resultTaxNotIncluded.reduce(
      (total, tax) => total + tax.totalTaxCalculations,
      0
    ),
  };
};
