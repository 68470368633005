/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { BiCheck } from "react-icons/bi";
import "../styles/orderSuccess.scss";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { useDispatch } from "react-redux";
import { useMainSlice } from "../../../pages/main/actions";

export default function OrderSuccess({ closeModal }) {
  const history = useHistory();
  const { table } = queryString.parse(window.location.search);
  const dispatch = useDispatch();
  const { actions } = useMainSlice();
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(actions.clearPaymentVerifiedDetails());
  }, []);
  const handleDone = () => {
    let url = `${window.location.pathname}`;
    if (table) {
      url = url + "?table=" + table;
    }

    history.push(url);
    closeModal();
  };
  return (
    <div className="OrderSuccess">
      <BiCheck className="checkIcon" />
      <h4>{t("cart.success")}</h4>
      <p>{t("cart.order_place_success")}</p>
      <button className="main-button" onClick={() => handleDone()}>
        {t("cart.done")}
      </button>
    </div>
  );
}
