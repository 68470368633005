/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { Modal } from "reactstrap";
import "../styles/cartModal.scss";
import { MdOutlineClose } from "react-icons/md";
import CartOrders from "./CartOrders";
import configs from "../../../configs";
import {
  selectOrderSavingSucceeded,
  selectStripePaymentIntentStatus,
  selectRestaurantUrl,
  selectStripeConenctedAccountId,
  selectRestaurantId,
  selectIsAdyenPaymentDetailsLoading,
  selectAdyenPaymentDetails,
  selectIsStripePaymentIntentloading,
} from "../../../pages/main/selectors";
import CartPaymentType from "./CartPaymentType";
// import CartPayment from './CartPayment';
import { MODAL_STATUS } from "../constants";
import { useSelector, useDispatch } from "react-redux";
import OrderSuccess from "./OrderSuccess";
import StripeCheckoutForm from "./StripeCheckoutForm";
import queryString from "query-string";
import { useMainSlice } from "../../../pages/main/actions";
import { v4 as uuidv4 } from "uuid";
import Loading from "../../Loading";

export default function CartModal(props) {
  const orderSavingSucceeded = useSelector(selectOrderSavingSucceeded);
  const restaurantUrl = useSelector(selectRestaurantUrl);
  const restaurantId = useSelector(selectRestaurantId);
  const accountId = useSelector(selectStripeConenctedAccountId);
  const [modalStatus, setModalStatus] = useState(MODAL_STATUS.PLACE_ORDER);
  const stripePaymentIntentStatus = useSelector(
    selectStripePaymentIntentStatus
  );
  const dispatch = useDispatch();
  const { actions } = useMainSlice();
  const { payment_intent, redirectResult } = queryString.parse(
    window.location.search
  );
  const location = window.location;
  const uuid = uuidv4();
  const isAdyenPaymentDetailsLoading = useSelector(
    selectIsAdyenPaymentDetailsLoading
  );
  const adyenPaymentDetails = useSelector(selectAdyenPaymentDetails);
  const isStripePaymentIntentloading = useSelector(
    selectIsStripePaymentIntentloading
  );

  useEffect(() => {
    if (orderSavingSucceeded) {
      setModalStatus(MODAL_STATUS.SUCCEEDED);
      dispatch(actions.reSetCartData({}));
    }
  }, [orderSavingSucceeded]);

  useEffect(() => {
    if (props.isOpen && !payment_intent) {
      setModalStatus(MODAL_STATUS.PLACE_ORDER);
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (payment_intent && accountId) {
      const cartData = localStorage.getItem(`cart-${configs.APP_DOMAIN}`);
      if (cartData) {
        dispatch(
          actions.getPaymentIntentDetails({
            intentId: payment_intent,
            accountId: accountId,
          })
        );
      }
    }
  }, [payment_intent, accountId]);

  useEffect(() => {
    if (redirectResult && restaurantId) {
      const cartData = localStorage.getItem(`cart-${configs.APP_DOMAIN}`);
      if (cartData) {
        dispatch(
          actions.getAdyenPaymentDetails({
            restaurantId,
            details: { redirectResult },
            orderReference: uuid,
          })
        );
      }
    }
  }, [redirectResult, restaurantId]);

  useEffect(() => {
    if (stripePaymentIntentStatus || adyenPaymentDetails) {
      setModalStatus(MODAL_STATUS.SELECT_PAYMENT_TYPE);
    }
  }, [stripePaymentIntentStatus, adyenPaymentDetails]);

  const ReturnModalContent = useCallback(() => {
    switch (modalStatus) {
      case MODAL_STATUS.PLACE_ORDER:
        return (
          <CartOrders
            setModalStatus={setModalStatus}
            closeModal={() => props.toggle()}
          />
        );
      case MODAL_STATUS.SELECT_PAYMENT_TYPE:
        return <CartPaymentType setModalStatus={setModalStatus} />;
      // case MODAL_STATUS.PAY:
      //   return <CartPayment setModalStatus={setModalStatus} />;
      case MODAL_STATUS.CHECKOUT:
        return <StripeCheckoutForm setModalStatus={setModalStatus} />;
      case MODAL_STATUS.SUCCEEDED:
        return <OrderSuccess closeModal={() => props.toggle()} />;
      default:
        return null;
    }
  }, [modalStatus]);

  return (
    <Modal
      isOpen={
        props.isOpen &&
        location.pathname !== `/${restaurantUrl}/login` &&
        location.pathname !== `/${restaurantUrl}/register`
      }
      className={`${
        modalStatus === MODAL_STATUS.PAY ? "pay" : ""
      } cart-modal-wrapper`}
    >
      {!payment_intent &&
        !isAdyenPaymentDetailsLoading &&
        !adyenPaymentDetails && (
          <MdOutlineClose className="closeIcon" onClick={props.toggle} />
        )}
      {isAdyenPaymentDetailsLoading || isStripePaymentIntentloading ? (
        <>
          <Loading className="payment-processing-loader" />
        </>
      ) : (
        <ReturnModalContent />
      )}
    </Modal>
  );
}
